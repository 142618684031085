<template>
    
</template>

<script>
export default {
    props:[
        'file_type'//群id，编辑群时必填，获取群管理企业（搜索用到）
    ],
    data() {
        return {};
    },

    mounted() {
        // this.onForItem()
    },

    methods: {
        // 循环调用
        // onForItem(){
        //     this.fotitem = setInterval(() => {
        //         this.ongetErShopExport()
        //     }, 2000);
        // },
        //调用导出生成接口
        ongetErShopExport(){
            var param = {
                file_type:this.file_type,
                deleteJiaZai:1
            }
            this.$service.get(this.$api.getErShopExport,param, (res)=> {
                if(res.code == '200'){
                    if(res.data != null){
                        //传送父页面参数
                        this.$parent.onGenerated(res.data);
                        // res.data.job_status == 1 ? '' : clearInterval(this.fotitem)
                        // if(res.data.job_status == 1){
                        // }else{
                            
                        // }
                    }
                }
            })
        },
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>