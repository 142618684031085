<template>
    <div class="lubanshop">
        <div class="padd20 borbut20" style="position: relative;">
            <!-- <div class="financiallistFrome" :class="spredPackup ? 'active' : ''"> -->
                <div class="topSearchSou">
                    <div class="searchdan">
                        <div class="title">商品ID：</div>
                        <el-input v-model="shopid" placeholder="请输入商品ID" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
                    </div>
                    <div class="searchdan">
                        <div class="title">商品标题：</div>
                        <el-input v-model="shop_title" placeholder="请输入商品标题"></el-input>
                    </div>
                    <div class="searchdan">
                        <div class="title">上线时间：</div>
                        <el-date-picker v-model="online_time" type="date" placeholder="上线时间" style="width:140px"></el-date-picker>
                    </div>
                    <div class="searchdan time">
                        <div class="title">更新日期：</div>
                        <el-date-picker v-model="pickerTime" @change="onPicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </div>
                    <div class="searchdan">
                        <div class="title">商品code：</div>
                        <el-input v-model="product_code" placeholder="请输入内容"></el-input>
                    </div>
                    <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                    <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right' >重置数据</el-button>
                </div>
            <!-- </div>
            <div class="RightBotton">
                
            </div>
            <div class="spred" @click="onSpred">{{spredPackup ? '收起' : '展开更多筛选'}} <i :class="spredPackup ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" /></div> -->
        </div>
        <div class="group-table padd20">
            <div class="duoshao">
                <div class="title">鲁班商品</div>
                <div class="lvtotal">
                    <el-button  type="primary" icon="iconfont icon-xiazai" @click="confitionDialog = true"> 导出</el-button>
                     <!-- 总条数：{{lvtotal}} 条-->
                </div>
            </div>
            <el-table ref="filterTable" :data="ErShopList" style="width: 100%">
                <el-table-column prop="id" label="商品编号"></el-table-column>
                <el-table-column prop="account_port" label="商品图片">
                     <template slot-scope="scope">
                         <el-tooltip placement="left">
                            <div slot="content"><img :src="scope.row.shop_img" style="width:300px;" alt=""></div>
                            <img :src="scope.row.shop_img" style="width:50px;height:50px" alt="">
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="account_port" label="商品类名" width="300">
                     <template slot-scope="scope">
                         <el-input v-model="scope.row.shop_title_like" placeholder="请输入商品类名" @blur="onShopTitle(scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="account_port" label="商品标题" width="300">
                     <template slot-scope="scope">
                         <span :title="scope.row.shop_title">{{scope.row.shop_title}}</span>
                    </template>
                </el-table-column>
                
                <el-table-column prop="account_port" label="商品上线时间">
                     <template slot-scope="scope">
                         {{dateFtt("yyyy-MM-dd",new Date(scope.row.online_time))}}
                    </template>
                </el-table-column>
                <el-table-column prop="account_port" label="商品价格">
                     <template slot-scope="scope">
                         <span v-if="scope.row.price == scope.row.price_max">{{scope.row.price}}</span>
                         <span v-else>{{scope.row.price}} ~ {{scope.row.price_max}}</span>
                    </template>
                </el-table-column>
                
                <el-table-column prop="today_volume" label="昨日销量"></el-table-column>
                <el-table-column prop="three_total" label="三日销量"></el-table-column>
                <el-table-column prop="seven_total" label="七日销量"></el-table-column>
                <el-table-column prop="sales_volume" label="总销量"></el-table-column>
            </el-table>
            <div class="ShopPag">当前第{{paramPage}}页<el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" prev-text="上一页" next-text="下一页" :current-page.sync="paramPage"></el-pagination></div>
        </div>
        <!-- 导出 -->
        <el-dialog title="导出列选择" :visible.sync="confitionDialog" width="520px" @close="onCancel">
			<div class="dialog-inside lubanshopedialog">
                <el-checkbox-group v-model="ShopCheckList" @change="onShopCheChange">
                    <el-checkbox v-for="(item,key) in cityList" :key="key" :label="item.param">{{item.text}}</el-checkbox>
                </el-checkbox-group>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="default" @click="onCancel" class="button-Dialog">取 消</el-button>
				<el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
			</div>
		</el-dialog>
        <!-- <Export ref="Export" file_type="1"></Export> -->
    </div>
</template>

<script>
import Export from '@/views/commonSearch/export'
export default {
    data() {
        return {
            paramData:{},//表格参数
            // spredPackup:false,//展开搜索
            shopid:"",//商品id
            shop_title:"",//商品标题
            online_time:"",//上线时间
            pickerTime:null,//开始日期结束日期
            product_code: "", //商品code
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页
            ErShopList:[],//商品列表
            job_status:'',//导出判断字段
            file_link:'',//链接地址
            confitionDialog:false,//导出弹窗
            ShopCheckList:['shop_title', 'price', 'shop_link', 'online_time', 'seven_total', 'three_total', 'today_volume', 'date', 'sales_volume'],//导出选中
            cityList:[
                {param:'shop_title',text:'商品标题',},
                {param:'price',text:'商品价格',},
                {param:'shop_link',text:'商品链接',},
                {param:'online_time',text:'上线时间',},
                {param:'date',text:'更新日期',},
                {param:'today_volume',text:'昨日销量',},
                {param:'three_total',text:'三日销量',},
                {param:'seven_total',text:'七日销量',},
                {param:'sales_volume',text:'总销量',}
            ],//导出默认
        };
    },
    components: {
        Export,
    },
    mounted() {
        this.ongetErShopList()
    },

    methods: {
        //给商品起别名
        onShopTitle(data){
            if(!data.shop_title_like){this.$message({message: '修改失败，商品类名不能为空',type: 'warning'}); return }
            var param = {
                shop_title_like:data.shop_title_like,
                shop_id:data.id,
                deleteJiaZai:true
            }
            this.$service.post(this.$api.editShopClassName,param, (res)=> {
                if(res.code == '200'){

                }
            })
        },
        //导出选择框改变触发
        onShopCheChange(val){
            this.ShopCheckList = val
        },
        //导出弹窗确认提交
        onSubmit(){
            this.confitionDialog = false
            this.onExport()
            // console.log(this.ShopCheckList)
            // this.$service.get(this.$api.getErShopList,param, (res)=> {
            //     if(res.code == '200'){
            //         this.ErShopList = res.data.data
            //         // this.lvtotal = res.data.total;
            //     }
            // })
        },
        //导出弹窗关闭
        onCancel(){
            this.confitionDialog = false
            this.ShopCheckList = ['shop_title', 'price', 'shop_link', 'online_time', 'seven_total', 'three_total', 'today_volume', 'date', 'sales_volume']//导出选中
        },
        //导出页面传送过来的参数
        // onGenerated(data){
        //     this.job_status = data.job_status
        //     this.file_link = data.file_link
        //     console.log(data)
        // },
        //展开更多搜索条件
        // onSpred(){
        //     this.spredPackup = !this.spredPackup
        // },
        //日期选择触发
        onPicker(){
        },
        //商品列表
        ongetErShopList(){
            this.ErShopList = []
            var param = this.paramData
            param.page = this.paramPage
            this.$service.get(this.$api.getErShopList,param, (res)=> {
                if(res.code == '200'){
                    this.ErShopList = res.data.data
                    // this.lvtotal = res.data.total;
                }
            })
        },
        //商品搜索
        onButtonInquire(){
            this.paramData = {
                id:this.shopid,//商品id
                shop_title:this.shop_title,//商品标题
                online_time:this.online_time ? this.dateFtt("yyyy-MM-dd", this.online_time) : "",//上线时间
                start_created_at: this.pickerTime ? this.pickerTime[0] : "", //开始日期
                end_created_at: this.pickerTime ? this.pickerTime[1] : "", //结束日期
                product_code: this.product_code, //商品code
            }
            this.paramPage = 1
            this.ongetErShopList()
        },
         // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetErShopList()
        },
        //导出
        onExport(){
            var param = this.paramData
            param.page = this.paramPage
            param.ex_hader = this.ShopCheckList
            this.$service.get(this.$api.getErShopListExport,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message: '导出成功，请在下载中心查看导出进度',type: 'success'});
                }
            })
        }
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.lubanshop{
    .RightBotton{
        width: 150px;
    }
    .topSearchSou{
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        .searchdan{
            .el-input{
                width: 170px;
            }
        }
        .searchdan:first-child{
            margin-left: 20px;
        }
    }
    .ShopPag{
        display: flex;
        align-items: center;
        justify-content: right;
        .lv-page{
            margin-left: 20px;
        }
    }
    
}
</style>
<style lang="scss">
.lubanshop{
    .lv-page{
        button{
            padding: 0 10px !important;
        }
    }
}
.lubanshopedialog{
    .el-checkbox{
        margin-bottom: 10px;
    }
}
</style>